<template>

  <div>

    <v-row style="margin-top: 50px" v-if="loading">
      <v-col cols="12" class="text-center">
        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
      </v-col>
    </v-row>

    <v-form v-else v-model="valid" ref="formEstoque" lazy-validation>
      <v-container fluid>
        <v-widget title="Movimentação de Estoque" :temCampoObrigatorio="true">
          <template v-slot:widget-content>
            <v-row dense>
              <v-col cols="12" sm="3">
                <buscar-produto v-model="codigoProduto" @produtoSelecionado="produtoSelecionado"></buscar-produto>
              </v-col>
              <v-col cols="12" sm="9">
                <v-text-field label="Descrição do Produto *" v-model="produto.descricao" readonly></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <campo-data label="Data *" v-model="movimento.data"></campo-data>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field label="Quantidade *" ref="quantidade" v-model="movimento.quantidade" :rules="[v => !!v && v !== '' || 'Quantidade Obrigatória', validarValor[0]]"></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-autocomplete label="Tipo *" v-model="movimento.tipo" :items="['Entrada', 'Saída']" :rules="[v => !!v && v !== '' || 'Tipo Obrigatório']"></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field label="Nota Fiscal" v-model="movimento.numero_nota"></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field label="Valor de Compra" v-model="movimento.valor_compra" :rules="validarValor"></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field label="Valor de Venda" v-model="movimento.valor_venda" :rules="validarValor"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field label="Responsável" v-model="movimento.responsavel"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <v-textarea label="Observação" v-model="movimento.observacao"></v-textarea>
              </v-col>
            </v-row>
          </template>

          <template v-slot:widget-footer-action>
            <v-btn text @click="cancelar">{{constantes.BOTAO_CANCELAR}}</v-btn>    
            <v-btn color="primary" :loading="carregando" @click="salvar" :disabled="!valid">{{textoBtnConfirmar}}</v-btn>
          </template>
        </v-widget>
    </v-container>

    </v-form>  
  </div>
</template>


<script>

import {mapState, mapGetters, mapActions, mapMutations} from "vuex";
import constantes from '@/util/constantes';
import validador from '@/util/validador';

export default {

  data: () => ({

    valid: false,
    validarData: [v => !v || (v && validador.dataNascimento(v) || 'Data Inválida')],
    validarValor: [v => !v || (v && v.match('^[0-9]+(\,[0-9]{1,2})?$') !== null) || "Valor Inválido"],
    codigoProduto: '',
    constantes: constantes,
    loading: false
  }),

  computed: {

    ...mapState('estoque', {
      movimento: 'movimentoAtual',
      produto: 'produto',
      carregando: 'carregando'
    }),
    
    ...mapGetters('estoque', {
      estaEditando: 'estaEditando',
      textoBtnConfirmar: 'textoBtnConfirmar',
    }),
  },

  methods: {

    produtoSelecionado(produto) {

      this.codigoProduto = produto.codigo;
      this.$store.commit('estoque/setProduto', produto);

      setTimeout(() => {
        let quantidade = this.$refs.quantidade;
        quantidade.focus();
      }, 300);
    },

    async salvar() {
      if (this.$refs.formEstoque.validate()) {
        try{
          await this.$store.dispatch('estoque/salvar');
          this.$router.push({ name: 'estoqueSimples' });
        }
        catch(erro){
          console.log('Houve um erro');
        }
      }
    },

    cancelar() {
      this.$store.commit('estoque/estadoInicial');
      this.$router.push({name: 'estoqueSimples'});
    }
  },

  async created(){
    
    try{

      this.loading = true;
      
      let movimento = this.$store.state.estoque.movimentoAtual;
      let estaEditando = this.$router.currentRoute.path.indexOf('editar') !== -1 ? true : false;
  
      if(estaEditando && (!movimento._id || movimento._id === '')){
        this.$store.commit('setMensagemErro', 'Selecione uma movimentação para editar');
        this.$router.push({ name: 'estoqueSimples' });
      }
      
      if(!estaEditando){
        this.$store.commit('estoque/estadoInicial');
      }
    }
    catch(erro){
      console.log(erro);
    }
    finally{
      this.loading = false;
    }
  }
};
</script>